<template>
  <div>
    <form-wizard
      :color="colorForm"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      :finish-button-text="$t('Form.Submit')"
      :next-button-text="$t('Form.Next')"
      :back-button-text="$t('Form.Previous')"
      class="mb-3 primary wizard-vertical"
      @on-complete="nurseSubmitted"
      ref="wizard"
    >
      <div class="d-flex">
        <GoBack class="mr-2" />
        <div class="d-flex" v-if="pickMedication">
          <div class="d-flex align-items-center">
            <span class="">
              <feather-icon size="24" icon="ThermometerIcon" />
            </span>
            <div class="d-flex flex-column">
              <p class="text-dark-75 font-weight-bolder font-size-sm mb-0">
                {{ pickMedication }}
              </p>
              <small
                href="#"
                class="text-primary text-muted font-weight-bolder mb-0"
                >Pick Up Medication</small
              >
            </div>
          </div>
        </div>
      </div>
      <template slot="step" slot-scope="props">
        <wizard-step
          :tab="props.tab"
          :index="props.index"
          @click.native="navigate(props.navigateToTab, props.index)"
        >
          <div slot="title" class="d-flex flex-column">
            <span class="stepTitle" style="">
              {{ props.tab.title }}
            </span>
            <small class="align-self-start text-primary">{{
              getSubTitle(props.index)
            }}</small>
          </div>
        </wizard-step>
      </template>

      <!-- patient details tab -->
      <tab-content
        :title="$t('FormWizard.PatientDetails')"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <PatientDetails :detail="detail" :facilityOptions="facilityOptions" />
        </validation-observer>
      </tab-content>

      <!-- medical history tab -->

      <tab-content :title="$t('FormWizard.MedicalHistory')">
        <validation-observer ref="infoRules" tag="form">
          <MedicalHistory
            v-if="medicalHistoryConditional"
            :medicalHistory="medicalHistory"
            :allergiesOptions="allergiesOptions"
            :ailmentOptions="ailmentOptions"
            :medicationsOptions="medicationsOptions"
          />
        </validation-observer>
      </tab-content>

      <!-- review system tab -->
      <tab-content :title="$t('FormWizard.ReviewOfSystem')">
        <validation-observer ref="" tag="form">
          <ReviewSystem
            :reviewSystemSelected="reviewSystemSelected"
            :reviewSystemLength="reviewSystemLength"
          />
        </validation-observer>
      </tab-content>

      <!-- vital signs  -->
      <tab-content
        :title="$t('FormWizard.VitalSigns')"
        :before-change="validationFormAddress"
      >
        <validation-observer ref="addressRules" tag="form">
          <VitalSign
            :vitalSign="vitalSign"
            :signVitalsOptions="signVitalsOptions"
          />
        </validation-observer>
      </tab-content>
    </form-wizard>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastNotification from "@/components/ToastNotification";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BBadge,
} from "bootstrap-vue";
import { required } from "@validations";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import axiosOV from "@/services/office-visit";

import PatientDetails from "./procedure/PatientDetails.vue";
import MedicalHistory from "./procedure/MedicalHistory.vue";
import VitalSign from "./procedure/VitalSign.vue";
import ReviewSystem from "./procedure/ReviewSystem.vue";
import GoBack from "@/components/ButtonBack.vue";
import { notificationCheck } from "@/mixins/NotificationSetup";

import { $themeColors } from "@themeConfig";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    WizardStep,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BBadge,

    vSelect,
    Cleave,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastNotification,

    PatientDetails,
    MedicalHistory,
    VitalSign,
    ReviewSystem,
    GoBack,
  },
  data() {
    return {
      colorForm: $themeColors.primary,
      detail: {
        name: "",
        lastName: "",
        phone: "",
        dateBirth: null,
        facility: null,
        room: null,
      },
      medicalHistory: {
        ailments: [],
        allergies: [],
        medicationInformation: [],
        current_medications: false,
        userId: null,
        historyId: null,
      },
      vitalSign: [],
      ailmentOptions: {
        data: null,
      },
      allergiesOptions: {
        data: null,
      },
      medicationsOptions: {
        data: null,
      },
      reviewSystemLength: {
        data: null,
      },
      reviewSystemSelected: {},
      facilityOptions: [],
      signVitalsOptions: [],
      loading: false,
      medicalHistoryConditional: false,
      pickMedication: null,
    };
  },
  mounted() {
    this.getDetails();
    this.$refs.wizard.activateAll();
  },
  methods: {
    getSubTitle(id) {
      if (id === 0) return "Office Visit Info";
      if (id === 1) return "Subjective";
      if (id === 2) return "Subjective";
      if (id === 3) return "Objective";
      return "";
    },
    navigate(navigateMethod, index) {
      let wizard = this.$refs.wizard;
      if (index === wizard.activeTabIndex + 1) {
        wizard.nextTab();
      } else {
        navigateMethod(index);
      }
    },
    getDetails() {
      this.loading = true;
      axiosOV
        .dataForNurse(this.$route.params.id)
        .then(({ all, office_visit, paginates, rooms }) => {
          const { type_vital_sign, review_of_system: CategoryReviewSystem } =
            all;

          this.signVitalsOptions = type_vital_sign;
          this.vitalSign = this.signVitalsOptions.map((item) => {
            return {
              id: item.id,
              value: null,
            };
          });

          this.facilityOptions.push(...rooms);

          const {
            appointments: {
              patients,
              facilities,
              pick_up_medication,
            },
            room,
            type_vital_sign: VitalSignUser,
            review_of_system,
          } = office_visit;

          if (pick_up_medication && pick_up_medication.name) {
            this.pickMedication = pick_up_medication.name;
          }

          this.detail = {
            name: patients.name,
            lastName: patients.lastname,
            phone: patients.phone,
            dateBirth: patients.date_birth,
            facility: facilities.name,
            room,
          };

          const { histories } = patients;
          this.medicalHistory = {
            ailments: histories.ailment,
            allergies: histories.allergies,
            medicationInformation: histories.medication_informations,
            current_medications: histories.current_medications,
            userId: histories.users_id,
            historyId: histories.id,
          };

          const { ailments, allergies, medication } = paginates;

          this.ailmentOptions.data = ailments.data;
          this.allergiesOptions.data = allergies.data;
          this.medicationsOptions.data = medication.data;

          this.medicalHistoryConditional = true;

          let nuevoObjeto = {};

          review_of_system.forEach((x) => {
            if (!nuevoObjeto.hasOwnProperty(x.category.name)) {
              nuevoObjeto[x.category.name] = [];
            }
            nuevoObjeto[x.category.name].push(x.assessmentable_id);
          });

          this.reviewSystemSelected = nuevoObjeto;
          this.reviewSystemLength.data = CategoryReviewSystem;

          VitalSignUser.forEach((elem, i) => {
            const index = this.vitalSign.findIndex(
              (item) => item.id === elem.id
            );
            if (index !== -1) {
              this.vitalSign[index].value = elem.pivot.value;
            }
          });

          this.loading = false;
        })
        .catch((error) => {
          this.$router.go(-1);
        });
    },
    nurseSubmitted() {
      const finallyReviewSystem = [];

      const reviewSystemSelected = this.reviewSystemSelected;
      for (const key in reviewSystemSelected) {
        if (Object.hasOwnProperty.call(reviewSystemSelected, key)) {
          finallyReviewSystem.push(...reviewSystemSelected[key]);
        }
      }
      for (const iterator in this.medicalHistory) {
        if (Array.isArray(this.medicalHistory[iterator])) {
          const nuevos = this.medicalHistory[iterator].map(
            (item) => item.id || item.historyble_id
          );
          this.medicalHistory[iterator] = nuevos;
        }
      }

      const data = {
        room: this.detail.room,
        vital_signs: this.vitalSign,
        review_of_system: finallyReviewSystem,
        history: {
          ...this.medicalHistory,
        },
      };
      axiosOV
        .procedureNurse(this.$route.params.id, data)
        .then((res) => {
          const { type, message } = notificationCheck(res);
          this.$refs.toast[type](message);
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$refs.toast.danger("the procedure has not been updated");
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "bootstrap/scss/functions";
@import "~@core/scss/base/bootstrap-extended/variables";
@import "bootstrap/scss/variables";
@import "~@core/scss/base/components/variables-dark";
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border: $primary 1px solid !important;
  background-color: $primary !important;
  color: white !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  background-color: transparent !important;
  color: $primary !important;
}
</style>
